<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <template v-if="items.length > 0">
      <v-row dense>
        <v-col>
          <h2>
            <!-- {{ formatNumber(items.length) }} Recent Customer Messages -->
          </h2>
        </v-col>
      </v-row>
    </template>
    <v-row dense>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['created_at']"
            :sort-desc="[true]"
          >
            <template v-slot:item.name="{ item }">
              <router-link
                style="cursor: pointer; color: blue; text-decoration: underline"
                :to="{ name: 'Customer', params: { campaign_id: item.mission_uuid, customer_id: item.customer_uuid } }"
              >
                {{ item.customer_first_name }} {{ item.customer_last_name }}
              </router-link>
            </template>
            <template #item.created_at="{value}">
              {{ formatIsoDate(value) }}
            </template>                   
            <!-- <template #item.read_at="{value}">
              {{ formatIsoDate(value) }}
            </template>                    -->

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import ChatovateService from '@/services/ChatovateService.js'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'

  export default {
    name: 'RecentCustomerMessages',
    components: {
      ErrorMessage,
    },
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      },
    },
    mixins: [formatterMixin, navigationMixin, titleMixin],
    data () {
      return {
        loadingMessage: 'Loading',
        showErrorDialog: false,
        items: [],
        search: '',
        loading: true,
        error: '',
        expanded: [],
        headers: [
          { text: 'Name', value: 'name', align: 'center' },
          { text: 'Message', value: 'body' },
          { text: 'Received At', value: 'created_at', align: 'center' },
        ]
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName']),
    },
    watch: {
      accountId () {
        this.loadMessages()
      },
      watch: {
      },
    },
    created () {
      this.loadMessages()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      loadMessages: function () {
        this.items = []
        this.loading = true
        ChatovateService.getRecentCustomerMessages()
          .then(response => {
            this.items = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
    },
  }
</script>
